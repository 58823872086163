import React, { useEffect, useState } from "react";
import { StandardPageProps, getSSTData } from "../utils/common";
import Head from "next/head";
import Script from "next/script";
import { useDispatch } from "react-redux";
import {
  getSiteSettingsAction,
  onWindowLocationUpdated,
} from "@/store/actions";
import {
  useBreadcrumbSelector,
  useLoginSelector,
  useRegisterSelector,
  useSiteSettingsSelector,
} from "@/store/selectors";
import apple_touch_icon from "../../public/apple-touch-icon.png";
import favicon_32x32 from "../../public/favicon-32x32.png";
import favicon_16x16 from "../../public/favicon-16x16.png";
import { callFROnPageChangeEvent } from "src/utils/fresh-relevance-actions";
import {
  AuthorProfile,
  BlogPostPage,
  ComponentSharedMetaSocial,
  SeoStructuredData,
} from "src/graphql/generated-strapi/types";
import { useRouter } from "next/router";
import { FHEventType } from "src/interfaces/serverSideTracking";
import getServerSideTracking, {
  getSessionData,
  postSSTCookiePreferences,
  postSSTFirstInteractionEvent,
} from "src/utils/postsst";
import { SubscribeModal } from "../components/SemanticTheme/SubscribeModal";
import { generateStructuredDataForBlogPostPage } from "@components/Utils";

type OtherProps = {
  nextHeaders?: any;
  cmsData?: BlogPostPage;
  logoImgUrl?: string;
  seoStructuredData?: SeoStructuredData;
  isBlogpostPage?: boolean;
  ShowSubscribeModal?: boolean;
};

type LocationData = {
  city: string | null;
  regionCode: string | null;
  postalCode: string | null;
  latitude: number | null;
  longitude: number | null;
};
interface BreadcrumbItem {
  "@type": string;
  position: number;
  item: {
    "@id": string;
    name: string;
  };
}

type Props = Partial<StandardPageProps & OtherProps>;

/**
 * Render a Page's head META properties using the Helmet component
 */
const PageProperties: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { siteSettings, deplayInSubscribeModal } = useSiteSettingsSelector();
  const { data: loginData } = useLoginSelector();
  const { data: registerData } = useRegisterSelector();
  const [isBrowser, setIsBrowser] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [trackingData, setTrackingData] = useState<LocationData>(null);
  const { breadcrumb: breadcrumbList } = useBreadcrumbSelector();

  const blogPostStructuredData = props?.isBlogpostPage
    ? generateStructuredDataForBlogPostPage(props?.cmsData, props?.logoImgUrl)
    : null;

  const structuredBreadcrumbList: BreadcrumbItem[] = breadcrumbList?.map(
    (item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      item: {
        "@id": !!item?.url
          ? `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/$/, "")}${
              item?.url
            }`
          : process.env.NEXT_PUBLIC_BASE_URL,
        name: item?.label,
      },
    }),
  );

  const breadcrumbJSON = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement:
      structuredBreadcrumbList?.length > 0 ? structuredBreadcrumbList : [],
  };

  const centralAddressData =
    props?.seoStructuredData?.CentralAddressData &&
    props?.ShowCentralAddressData
      ? JSON.stringify(props?.seoStructuredData?.CentralAddressData)
      : null;
  const centralStructuredData =
    props?.seoStructuredData?.CentralStructuredData &&
    props?.ShowCentralStructuredData
      ? JSON.stringify(props?.seoStructuredData?.CentralStructuredData)
      : null;

  const globalRobotsDirectivesEnabled =
    siteSettings?.robotsSettings?.globalRobotsDirectivesEnabled || false;

  const SeoStructuredData =
    props?.structuredData !== "null" ? props?.structuredData : false;

  const showSubscribeModal = props?.ShowSubscribeModal;
  /** Robots CMS checkboxes as a list */
  const robotsList: string[] = [];
  if (
    globalRobotsDirectivesEnabled
      ? siteSettings?.robotsSettings?.globalRobotsNoFollow
      : props?.RobotsNoFollow
  )
    robotsList.push("nofollow");
  if (
    globalRobotsDirectivesEnabled
      ? siteSettings?.robotsSettings?.globalRobotsNoIndex
      : props?.RobotsNoIndex
  )
    robotsList.push("noindex");
  if (
    globalRobotsDirectivesEnabled
      ? siteSettings?.robotsSettings?.globalRobotsNoArchive
      : props?.RobotsNoArchive
  )
    robotsList.push("noarchive");
  if (
    globalRobotsDirectivesEnabled
      ? siteSettings?.robotsSettings?.globalRobotsNoSnippet
      : props?.RobotsNoSnippet
  )
    robotsList.push("nosnippet");
  if (
    globalRobotsDirectivesEnabled
      ? siteSettings?.robotsSettings?.globalRobotsNoTranslate
      : props?.RobotsNoTranslate
  )
    robotsList.push("notranslate");
  if (
    globalRobotsDirectivesEnabled
      ? siteSettings?.robotsSettings?.globalRobotsNoImageIndex
      : props?.RobotsNoImageIndex
  )
    robotsList.push("noimageindex");

  let headers: any;

  if (props?.nextHeaders) {
    headers = JSON.parse(props?.nextHeaders);
    headers.city = trackingData?.city;
    headers.regionCode = trackingData?.regionCode;
    headers.postalCode = trackingData?.postalCode;
    headers.latitude = trackingData?.latitude;
    headers.longitude = trackingData?.longitude;
  }

  useEffect(() => {
    const fetchTrackingData = async () => {
      const sstData = await getSSTData();
      setTrackingData(sstData);
    };

    fetchTrackingData();
  }, []);

  useEffect(() => {
    let isFirstInteractionHandled = false;
    // event handler for click or scroll
    if (!!trackingData) {
      const postFirstInteractionEventToSST = (e: Event) => {
        if (!isFirstInteractionHandled) {
          postSSTFirstInteractionEvent({ url }, e.type as FHEventType);
          isFirstInteractionHandled = true;
        } else getSessionData({ url, headers });
      };

      //event handler for onetrust cookie
      const postCookieConsentToSST = (e: any) => {
        postSSTCookiePreferences(headers);
      };

      // on page load send url to sst
      const url = `${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath.replace(
        "/",
        "",
      )}`;
      const userData =
        loginData?.customer || registerData
          ? loginData?.customer
            ? loginData?.customer
            : registerData
          : undefined;

      const referrer = isBrowser ? document.referrer : "";
      getServerSideTracking({ url, referrer, userData, headers });

      // bind click and scroll events
      document.addEventListener("click", postFirstInteractionEventToSST);
      document.addEventListener("scroll", postFirstInteractionEventToSST);
      window.addEventListener("OTConsentApplied", postCookieConsentToSST);

      // unset on unmount
      return () => {
        document.removeEventListener("click", postFirstInteractionEventToSST);
        document.removeEventListener("scroll", postFirstInteractionEventToSST);
        window.removeEventListener("OTConsentApplied", postCookieConsentToSST);
      };
    }
  }, [router.asPath, trackingData]);

  useEffect(() => {
    setIsBrowser(typeof window !== "undefined");
    dispatch(onWindowLocationUpdated());
    dispatch(getSiteSettingsAction());
    callFROnPageChangeEvent();
  }, []);

  useEffect(() => {
    if (
      isBrowser &&
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1
    ) {
      document.body.style.fontSynthesisWeight = "none";
    }

    if (props?.FeefoReviewAPITag && isBrowser) {
      const script = document.createElement("script");
      script.src = process.env.NEXT_PUBLIC_FEEFO_URL ?? "";
      script.defer = true;
      document.body.insertBefore(script, document.body.lastElementChild);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [props, isBrowser]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const dismissedOrSubscribedToMarketing = localStorage.getItem(
        "dismissedOrSubscribedToMarketing",
      );

      if (showSubscribeModal && dismissedOrSubscribedToMarketing !== "true") {
        const timer = setTimeout(
          () => {
            setShowModal(true);
          },
          !!deplayInSubscribeModal ? deplayInSubscribeModal : 10000,
        );

        return () => clearTimeout(timer);
      }
    }
  }, [showSubscribeModal, isBrowser]);

  return (
    <>
      <Head>
        <title>{props?.metaTitle}</title>
        <meta name="description" content={props?.metaDescription} />
        <meta name="keywords" content={props?.keywords} />
        <meta name="robots" content={robotsList.join(", ")} />
        <link rel="canonical" href={props?.canonicalURL} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${apple_touch_icon?.src ?? "/apple-touch-icon.png"}`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${favicon_32x32?.src ?? "/favicon-32x32.png"}`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${favicon_16x16?.src ?? "/favicon-16x16.png"}`}
        />
        <meta property="og:title" content={props.OGTitle} />
        <meta property="og:description" content={props.OGDescription} />
        <meta property="og:image" content={props.OgImage} />
        <meta property="og:url" content={props.OGUrl} />
        <meta property="og:site_name" content={props.OGSiteName} />
        <meta property="og:locale" content={props.OGLocale} />
        <meta property="og:type" content={props.OGType} />
        <meta property="fb:admins" content={props.OGFBAdmins} />
        {!!props?.metaSocial?.length &&
          props?.metaSocial.map(
            (socialItem: ComponentSharedMetaSocial, key: number) => (
              <React.Fragment key={`${socialItem.__typename}__${key}`}>
                <meta property="card" content={socialItem.card} />
                <meta property="title" content={socialItem.title} />
                <meta property="description" content={socialItem.description} />
                <meta property="site" content={socialItem.site} />
                <meta property="creator" content={socialItem.creator} />
                <meta
                  property="image"
                  content={socialItem.image?.data?.attributes?.url}
                />
              </React.Fragment>
            ),
          )}
        <meta
          name="facebook-domain-verification"
          content="2dnagzh3fslll41si09xyc0xu3lr3s"
        />
      </Head>
      <Script
        id="fr-script"
        src="//d81mfvml8p5ml.cloudfront.net/vdsgkded.js"
        async={true}
      />
      {SeoStructuredData && (
        <Script id="seo-script" type="application/ld+json" async>
          {SeoStructuredData}
        </Script>
      )}
      {showModal && (
        <SubscribeModal
          title="Subscribe to our emails and receive an exclusive 10% discount"
          emailLabel="Email"
          firstNameLabel="First name"
          submitLabel="Subscribe"
          privacyLinkUrl="https://www.forestholidays.co.uk/privacy-policy/"
          receiveMessageByEmail="I am happy to receive forest news, promotions and offers by email."
          moreInfoText="For more information about how we use your data please see our "
          privacyLinkLabel="privacy policy."
        />
      )}
      {blogPostStructuredData && (
        <Script id="blogpost-structured-data" type="application/ld+json" async>
          {blogPostStructuredData}
        </Script>
      )}
      {breadcrumbList?.length > 0 && (
        <Script id="seo-breadcrumb-list" type="application/ld+json" async>
          {JSON.stringify(breadcrumbJSON)}
        </Script>
      )}
      {centralAddressData && (
        <Script id="central-address-data" type="application/ld+json" async>
          {centralAddressData}
        </Script>
      )}
      {centralStructuredData && (
        <Script
          id="central-structure-data"
          type="application/ld+json"
          async={true}
        >
          {centralStructuredData}
        </Script>
      )}
    </>
  );
};

export default PageProperties;
